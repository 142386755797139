@media only screen and (min-width: 800px) {
}

@media only screen and (max-width: 800px) {
}

.task_link {
  text-decoration: none;
  color: black;
}

.task_card {
  width: 100%;
  height: 120px;
  border-radius: 20px;
  background-color: white;
}

.task_card_title {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 20px;
  cursor: pointer;
}

.task_card_divider {
  width: 80%;
}
