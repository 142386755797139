.menu_element {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 40px auto;
}

.menu_element_image {
  width: 30px;
}

.menu_element_text_container {
  padding-top: 4px;
}

.menu_element_text {
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  color: black;
}
