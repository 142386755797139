.attribute_advise {
  width: 70%;
  color: #aaa;
}

.add_attribute_button{
  width: 100px
}

.separator {
  width: 70%;
}
